@charset "UTF-8";
* {
  box-sizing: border-box;
}

body {
  font-family: "Hiragino Kaku Gothic ProN","メイリオ","MS PGothic", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: 14px;
}

ul, ol {
  list-style: none;
}

p {
  line-height: 1.8;
}

.cmn-fixed_banner {
  display: none;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.cmn-fixed_banner--inner {
  display: none;
}

@media (max-width: 1280px) {
  .cmn-fixed_banner--inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 99px;
    background: #0c98fa;
    background: linear-gradient(90deg, #0c98fa 0%, #65dc8e 100%);
  }
}

@media (max-width: 668px) {
  .cmn-fixed_banner--inner {
    position: relative;
    height: 80px;
  }
}

.cmn-fixed_banner--link {
  line-height: 0;
}

.cmn-fixed_banner--nw {
  width: 100%;
  max-width: 454px;
}

@media (max-width: 668px) {
  .cmn-fixed_banner--nw {
    max-width: 365px;
  }
}

.pickup-large_banner {
  display: block;
}

.pickup-large_banner--link {
  line-height: 0;
}

.pickup-large_banner--pic {
  width: 100%;
}

@media (max-width: 1280px) {
  .pickup-large_banner {
    display: none;
  }
}
